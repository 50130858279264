import Preact from "preact";
export default function PageNav() {
  return (
    <>
      <div class="nav-item">
        <a href="/architectural-resources">Resources</a>
      </div>
      <div class="nav-item">
        <a href="/gallery">Gallery</a>
      </div>
      <div class="nav-item">
        <a href="/about">About</a>
      </div>
      <div class="nav-item">
        <a href="/resources">Documents</a>
      </div>
      <div class="nav-item nav-contact">
        <a href="/contact">Contact</a>
      </div>
    </>
  );
}
